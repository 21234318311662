.footerContainer {
    padding: 1% 1%;
    border-top: 2px solid var(--text-color);
}

.topSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}

.logoSection {
    flex: 1;
}

.socialSection {
    flex: 1;
    text-align: right;
}

.socialIcons {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 0px;
}

.navSection {
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0px;
}

.navLinksLeft {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 40px;
}

.navLinksRight {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 20px;
}

.bottomSection {
    text-align: center;
    font-size: 0.9rem;
    margin-top: 0px;
}

@media (max-width: 768px) {
    .footerContainer {
        padding: 10px;
    }

    .topSection {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 0px;
    }

    .socialSection {
        text-align: center;
    }

    .navSection {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .navLinksLeft,
    .navLinksRight {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        margin-bottom: 0px;
    }
}
