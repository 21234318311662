.brandLogo {
    position: relative;
    z-index: 10;
}

.brandLogo button {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    color: var(--text-color);
    position: relative;
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.hidden {
    opacity: 0;
    visibility: hidden;
    transition:
        opacity 0.5s ease,
        visibility 0s 0.5s;
}

.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease;
}

.navbarBrandHighlight {
    position: absolute;
    bottom: -5px;
    height: 2px;
    background-color: var(--text-color);
    width: 100%;
    left: 0;
    animation: slidingHighlight 30s ease-in-out infinite;
}

.desktopLogo {
    display: none;
}

.mobileLogo {
    display: inline;
}

@keyframes slidingHighlight {
    0%,
    100% {
        width: 10%;
        left: 0%;
    }
    12.5%,
    62.5% {
        width: 100%;
        left: 0%;
    }
    37.5%,
    87.5% {
        width: 100%;
        left: 0%;
    }
    25%,
    75% {
        width: 15%;
        left: 85%;
    }
    50% {
        width: 20%;
        left: 0%;
    }
}

@media (min-width: 769px) {
    .desktopLogo {
        display: inline;
    }

    .mobileLogo {
        display: none;
    }
}
