.icon {
    color: var(--not-hover-color);
    transition: color 0.3s ease;
}

.icon:hover {
    color: var(--text-color);
}

.medium {
    font-size: 1.5rem;
}

.large {
    font-size: 2rem;
}
