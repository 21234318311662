.navLink {
    text-decoration: none;
    color: var(--not-hover-color);
}

.navLink:hover {
    text-decoration: none;
    color: var(--text-color);
}

@media (max-width: 768px) and (orientation: portrait) {
    .navLink {
        padding-top: 1rem;
    }
}
