.worksContainer {
    padding: 0% 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-size: 5vw;
    font-weight: bold;
}

@media (max-height: 768px) and (orientation: landscape) {
    .worksContainer {
        min-height: 150vh;
    }
}
