:root {
    --background-color: black;
    --text-color: white;
    --not-hover-color: rgb(193, 193, 193);
    --hover-link-color: #ffffff;
}

* {
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    scroll-behavior: smooth;
    background-color: var(--background-color);
    color: var(--text-color);
}
