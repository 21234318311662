.navbarContainer {
    padding: 0% 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.desktopMenu {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 40px;
}

.desktopMenu li {
    list-style: none;
}

.mobileMenu {
    display: none;
}

@media (max-width: 768px) {
    .navbarContainer {
        padding: 1% 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .desktopMenu {
        display: none;
    }
    .mobileMenu {
        display: flex;
        align-items: center;
    }
}
