.progressWrap {
    position: fixed;
    right: 10px;
    bottom: 20px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    background-color: rgba(136, 136, 136, 0.2);
    color: var(--color);
    backdrop-filter: blur(10px);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    transition:
        all 200ms linear,
        background-color 0.2s;
}

.activeProgress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition:
        transform 0.3s ease,
        opacity 0.5s ease;
}

.activeProgress:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px var(--background-color);
}

.activeProgress:active {
    transform: scale(0.9);
    box-shadow: 0 2px 10px var(--background-color);
}

@media (max-width: 768px) and (orientation: portrait) {
    .progressWrap {
        height: 30px;
        width: 30px;
    }
}
