.aboutContainer {
    padding: 0% 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.leftSide,
.rightSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.leftSide {
    width: auto;
}

.rightSide {
    width: 50%;
    align-items: flex-start;
    font-size: 1.7vw;
}

.profilePicture {
    width: 25%;
    height: auto;
    margin-bottom: 30px;
}

.textAligned {
    text-align: left;
    margin: 0;
    margin-bottom: 1rem;
}

.introduction {
    margin-bottom: 4rem;
}

.h2 {
    font-size: 5vw;
}

.h3 {
    color: var(--not-hover-color);
}

.aboutSection p {
    width: 90%;
}

.contactDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.emailLink {
    color: white;
    text-decoration: none;
    position: relative;
    white-space: nowrap;
}

.socialIcons {
    margin-left: 20px;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.contactMeLink {
    color: var(--not-hover-color);
    text-decoration: none;
    position: relative;
    white-space: nowrap;
    margin-left: auto;
    padding-left: 30px;
}

.contactMeLink:hover {
    color: var(--hover-link-color);
}

.contactMeLink::after {
    content: '';
    display: block;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: var(--color);
}

@media (max-width: 768px) and (orientation: portrait) {
    .aboutContainer {
        flex-direction: column;
        min-height: 100vh;
    }

    .leftSide,
    .rightSide {
        width: 100%;
    }

    .rightSide {
        font-size: 3.5vw;
    }

    .profilePicture {
        width: 40%;
        margin-bottom: 20px;
    }

    .h2,
    .h3 {
        font-size: 8vw;
        text-align: left;
        margin-bottom: 20px;
    }

    .h3 {
        font-size: 4vw;
    }

    .socialIcons {
        padding-left: 10;
        list-style: none;
        display: flex;
        justify-content: flex-end;
        gap: 15px;
    }

    .emailLink,
    .contactMeLink {
        padding-top: 0;
        text-decoration: none;
    }

    .contactMeLink {
        padding-left: 3px;
    }

    .contactSection {
        width: 100%;
    }
}

@media (max-height: 768px) and (orientation: landscape) {
    .aboutContainer {
        min-height: 150vh;
    }

    .textAligned {
        text-align: left;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    .introduction {
        margin-bottom: 3rem;
    }
}
