.homeContainer {
    padding: 5% 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.homeImageContainer {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    z-index: 1;
    background: no-repeat left center / cover;
    overflow: hidden;
}

.homeImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.homeTextContainer {
    position: absolute;
    object-fit: cover;
    top: 40vh;
    left: 42%;
    width: 55%;
    z-index: 1;
}

.homeFirstname,
.homeLastname {
    font-size: 5vw;
    margin-bottom: 3px;
}

.homeFirstname {
    animation: slideInFromLeft 1.5s ease-out;
    transform-origin: left center;
}

.homeDetailsContainer {
    margin-top: -1rem;
    display: flex;
    align-items: center;
    transform: translate(18%, 0);
}

.homeLastname {
    font-weight: bold;
    text-align: right;
    margin-top: 0rem;
    margin-left: 2vw;
    animation: slideInFromRight 1.5s ease-out;
}

.homePhotographer {
    font-size: 1.5vw;
    text-align: right;
    margin-right: 20vw;
    margin: 0;
    line-height: 1;
    display: inline-block;
    color: var(--not-hover-color);
    animation: slideInFromLeft 1.5s ease-out;
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideModePhotographerFromLeft {
    from {
        transform: translate(-100%, -310%);
        opacity: 0;
    }
    to {
        transform: translate(-45%, -310%);
        opacity: 1;
    }
}

.homeMenuItem {
    display: none;
}

@media (max-width: 768px) and (orientation: portrait) {
    .homeImageContainer {
        width: 100vw;
        height: 100vh;
    }

    .homeImageContainer img {
        object-fit: cover;
        position: fixed;
        height: auto;
        min-height: 50vh;
        z-index: -1;
        pointer-events: none;
    }

    .homeTextContainer {
        text-align: center;
        top: 73vh;
        width: 82%;
        left: 9%;
    }

    .homeFirstname {
        padding-right: 40%;
    }

    .homeFirstname,
    .homeLastname {
        margin-bottom: 0;
        font-size: 8vw;
    }

    .homePhotographer {
        font-size: 3.3vw;
    }

    .homeDetailsContainer {
        margin-top: -9px;
        display: flex;
        align-items: center;
        width: 87%;
        transform: translate(15%, 0);
    }
}

@media (max-height: 768px) and (orientation: landscape) {
    .homeContainer {
        min-height: 150vh;
    }
}
