.hamburgerMenu {
    position: relative;
    z-index: 1001;
}

.menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    z-index: 1002; /* Ensure the button is above the menu content */
    position: relative;
}

.line {
    fill: none;
    stroke: var(--text-color);
    stroke-width: 6;
    transition:
        stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
    stroke-dasharray: 60 207;
}

.line2 {
    stroke-dasharray: 60 60;
}

.line3 {
    stroke-dasharray: 60 207;
}

.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
}

.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
}

.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
}

.menuContent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    z-index: 1000;
    padding: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
