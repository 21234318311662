.languageToggleContainer {
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    font-size: 0.9rem;
    width: 70px;
}

.languageToggleHighlight {
    position: absolute;
    height: 100%;
    top: 0;
    width: 50%;
    background-color: var(--text-color);
    transition: transform 0.3s ease-in-out;
    z-index: 0;
}

.languageOption {
    flex: 1;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5em;
    transition: color 0.3s ease;
    color: var(--text-color);
    font-weight: bold;
}

.languageOption.selected {
    color: var(--background-color);
    font-weight: bold;
}

@media (max-width: 768px) and (orientation: portrait) {
    .languageToggleContainer {
        margin-top: 2rem;
        align-items: center;
    }

    .navbarContainer > .languageToggleContainer {
        display: none;
    }
}
