.contactContainer {
    padding: 5% 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.contactForm,
.contactHeader {
    flex: 70%;
}

.contactForm h2 {
    font-size: 5vw;
}

.inputGroup {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 40px;
    justify-content: space-between;
}

.inputGroup input.nameInput,
.inputGroup input.emailInput {
    width: calc(49% - 30px);
}

.inputGroup input,
.inputGroup textarea {
    min-height: 70px;
    font-weight: bold;
    width: 110%;
    padding: 10px;
    background: none;
    border-color: transparent;
    border-bottom: 1px solid var(--not-hover-color);
    color: var(--not-hover-color);
    transition: border-color 0.3s;
    font-size: 16px;
}

.inputGroup input::placeholder,
.inputGroup textarea::placeholder {
    color: var(--not-hover-color);
}

.inputGroup input:focus,
.inputGroup textarea:focus {
    outline: none;
    border-bottom: 1px solid var(--text-color);
}

.inputGroup textarea {
    resize: none;
    overflow: hidden;
    min-height: 50px;
}

.inputGroup input:not(:placeholder-shown),
.inputGroup textarea:not(:placeholder-shown) {
    color: var(--text-color);
}

.submitButton {
    font-weight: bold;
    background-color: transparent;
    color: var(--not-hover-color);
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 1rem;
    display: block;
    width: 100%;
    text-align: left;
    margin-top: 40px;
    transition: all 0.3s ease;
}

.submitButton:hover {
    color: var(--text-color);
}

.inputGroup input:hover,
.inputGroup textarea:hover,
.inputGroup input:focus,
.inputGroup textarea:focus {
    color: var(--text-color);
    border-bottom: 1px solid var(--text-color);
}

.inputGroup input:hover::placeholder,
.inputGroup input:focus::placeholder,
.inputGroup textarea:hover::placeholder,
.inputGroup textarea:focus::placeholder {
    color: var(--text-color);
}

.inputGroup textarea:focus {
    border-bottom: 1px solid var(--text-color);
    color: var(--text-color);
}

.fadeOut {
    animation: fadeOut 1s forwards;
}

.fadeIn {
    animation: fadeIn 1s forwards;
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.showThankYou {
    opacity: 1;
    animation: showThankYou 0.5s forwards;
}

.fadeThankYou {
    animation: fadeThankYou 1s forwards;
}

@keyframes showThankYou {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeThankYou {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@media (max-width: 768px) and (orientation: portrait) {
    .contactContainer {
        text-align: left;
    }

    .contactForm {
        max-width: 500px;
        margin: auto;
    }

    .contactForm h2 {
        font-size: 8vw;
    }

    .inputGroup input.emailInput {
        margin-right: 5%;
    }

    .inputGroup input,
    .inputGroup textarea {
        width: 95%;
    }

    .contactForm h2 {
        transform: translate(2%, -30%);
    }

    .contactForm .thankYouMessage {
        font-size: 28px;
    }

    .formAligned {
        align-items: center;
    }

    .submitButton {
        text-align: center;
    }
}

@media (max-height: 768px) and (orientation: landscape) {
    .contactContainer {
        min-height: 150vh;
    }
}
